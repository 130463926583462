import * as ENV from '../env';
import { io } from 'socket.io-client';
const socket = io(ENV.socketServer);

/**
 * @TODO :
 * - localStorage pour les messages non lus ?
 * - ajout d'un nouveau message
 */
export default class ChatManager
{
    chat() {
        return {
            chatOpened: true,
            chatNewMsg: false,
            chatNewMsgValue: 0, 
            chatMsgs: [],
            /**
             * Gestion du bouton pour afficher le chat
             */
            toggleChat() {
                this.chatOpened = !this.chatOpened;
                if (this.chatOpened) {
                    this.chatNewMsg = false;
                    this.chatNewMsgValue = 0;
                    this.messageViewed();
                }
            },
            /**
             * Réception d'un nouveau message
             */
            addMessage(msg) {
                this.chatMsgs.push({
                    id: msg.messageId,
                    title: msg.title,
                    date: msg.date,
                    content: msg.content,
                    isRead: false
                });
                if (!this.chatOpened) {
                    this.chatNewMsg = true;
                    this.chatNewMsgValue++;
                } else {
                    this.chatNewMsg = false;
                    this.chatNewMsgValue = 0;
                    this.messageViewed();
                }
            },
            /**
             * Trigger l'état "vu" des messages
             */
            messageViewed() {
                var self = this;

                this.chatMsgs.forEach(function(message, key) {
                    if (!message.isRead) {
                        socket.emit('messageViewed', { messageId: message.id });
                        self.chatMsgs[key].isRead = true;
                    }
                });

                setTimeout(() => {
                    let messagesWrapper = document.getElementById('chat-message');
                    messagesWrapper.scrollTop = messagesWrapper.scrollHeight;
                }, 400);
            },

            /**
             * Initialisation
             */
            initChat() {
                const chatManager = this;
                socket.on('chatMessage', function(data) {
                    if (data.shopId == parseInt(localStorage.getItem('id_resto'))) {
                        chatManager.addMessage(data);
                        var audioElement = document.getElementById('xyz');
                        audioElement.play();
                    }
                });
                
                fetch(ENV.url + 'wp-json/ginogina/restaurant/' + localStorage.getItem('id_resto') + '/messages', {
                    method: 'GET',
                    headers: { 
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem('bearer')
                    }
                })
                .then((response) => {
                    return response.json().then((response) => {
                        let allMessages = response;
                        
                        if (null != allMessages && allMessages.length > 0) {
                            allMessages.forEach((msg) => {
                                this.chatMsgs.push({
                                    id: msg.messageId,
                                    title: msg.title,
                                    date: msg.date,
                                    content: msg.content,
                                    isRead: msg.isRead
                                });

                                if (!msg.isRead) {
                                    if(!this.chatOpened) {
                                        this.chatNewMsg = true;
                                        this.chatNewMsgValue++;
                                    } else {
                                        this.chatNewMsg = false;
                                        this.chatNewMsgValue = 0;
                                        this.messageViewed();
                                    }
                                }
                            })
                        }
                    });
                });
            }
        }
    }
}