import '@themesberg/flowbite';
import Alpine from 'alpinejs';
import LoginManager from './modules/LoginManager';
import OrderManager from './modules/OrderManager';
import CalendarManager from './modules/CalendarManager';
import ChatManager from './modules/ChatManager';
import timeout from '@victoryoalli/alpinejs-timeout';
import '../resources/css/styles.css';
import 'tailwindcss/dist/tailwind.min.css';

Alpine.plugin(timeout)

window.LoginManager = new LoginManager();
window.OrderManager = new OrderManager();
window.CalendarManager = new CalendarManager();
window.ChatManager = new ChatManager();

window.Alpine = Alpine;
Alpine.start();
